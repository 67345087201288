$colors: (
  First: #007bff,
  Second: #009fa8,
  Third: #00345f,
  Forth: #00576f,
  Fifth: #187999,
  Sixth: #57c6eb,
  Seventh: #80cfd3,
  Red: #df3a3a,
  Yellow: #ffe600,
);

@use "sass:map";
@use "@material/theme" with (
  $primary: map.get($colors, "Fifth"),
  $secondary: map.get($colors, "Second"),
  $background: #fff
);
@use "@material/typography/mdc-typography";
@use "@material/button/mdc-button";
@use "@material/button";
@import "./css/normalize";

html {
  --mdc-typography-font-family: Raleway, Roboto, Helvetica, sans-serif;
  --mdc-typography-body1-font-size: 16px;
}

body {
  margin: 0;
  overflow: hidden;
}

.connection-line {
  stroke: #cfcfcf;
  stroke-width: 1px;
  stroke-linecap: round;
  fill: transparent;
}

#details-container {
  .navigation-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    margin: 8px;

    @media (orientation: portrait) {
      justify-content: space-around;
    }

    @media (orientation: landscape) {
      position: absolute;
      top: -52px;
      right: 0px;
    }

    button {
      margin-left: 8px;
    }
  }

  .details-portrait-title {
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 0;
    font-size: 28px;
  }

  #experience-subheader-container {
    pointer-events: none;

    @media (orientation: portrait) {
      position: absolute;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 0 17px;

      #geo-countries {
        position: absolute;
        top: -100px;
      }
    }

    @media (orientation: landscape) {
      position: fixed;
      display: flex;
      flex-direction: column;
      align-items: center;

      > * {
        margin-top: 16px;
      }
    }

    h2 {
      line-height: 1.5rem;
    }

    button {
      pointer-events: all;
      order: 2;
    }

    #geo-instructions {
      text-align: center;
    }
  }

  .content-container {
    // width: 100%;
    height: 100%;
    // overflow-y: scroll;
    padding: 0 17px;
    box-sizing: content-box; /* So the width will be 100% + 17px */
    max-height: inherit;

    h2,
    h3,
    h4 {
      opacity: 0.87;
    }

    p,
    li {
      @extend .mdc-typography--body1;
      opacity: 0.65;
    }

    ol li ul li {
      opacity: 1;
    }

    .mdc-button {
      margin: 0 10px 10px 0;
    }
  }

  width: 960px;

  @media only screen and (max-width: 680px) and (min-width: 320px) {
    width: 65vw;
  }

  @media only screen and (max-width: 820px) and (min-width: 680px) {
    width: 460px;
  }

  @media only screen and (max-width: 992px) and (min-width: 820px) {
    width: 560px;
  }

  @media only screen and (max-width: 1200px) and (min-width: 992px) {
    width: 680px;
  }

  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    width: 720px;
  }

  @media (orientation: portrait) {
    width: 100vw;
    left: 0;
  }

  @media (orientation: landscape) {
    max-height: 70vh;
    left: 30vw;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.table-wrapper {
  padding: 17px 0;
}

table {
  border-collapse: collapse;
  border: 1px solid #a5a5a5;

  th,
  td {
    @extend .mdc-typography--body1;
    padding: 8px 16px;
    border: 1px solid #cfcfcf;
    min-width: 200px;
    font-size: 14px;
  }

  th:first-child,
  td:first-child {
    min-width: 150px;
  }

  th {
    opacity: 0.87;
    font-weight: bold;
  }

  td {
    opacity: 0.65;
  }
}

#title-section {
  h1,
  h2 {
    margin: 0 0 20px 10px;
  }

  h1 {
    line-height: 1.2em;
    font-size: 48px;
    font-weight: 800;
    color: #187999;
  }

  h1 .booster {
    font-weight: 900;
    color: #009fa8;
  }

  h2 {
    opacity: 0.7;
  }

  img {
    margin-left: 8px;
  }
}

#tooltip {
  background-color: #009fa8;
  opacity: 0.6;
  border-radius: 8.5px;
  padding: 10px 20px;
  color: white;
  font-weight: 700;
  position: absolute;
  visibility: hidden;
}

.simplebar-scrollbar.simplebar-scrollbar:before {
  background-color: #009fa8;
}

.simplebar-track {
  background-color: #e8e8e8;
  border-radius: 10px;
}

.simplebar-content-wrapper,
.simplebar-content-wrapper:focus,
.simplebar-content-wrapper:hover {
  outline: none;
}

#bottom-text-fader {
  position: absolute;
  bottom: -4px;
  background-image: linear-gradient(to top, white, rgba(255, 255, 255, 0));
  width: 100%;
  height: 100px;
  pointer-events: none;
}

footer {
  position: fixed;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  #first-row {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }

  span {
    @extend .mdc-typography--body1;
    opacity: 0.65;
  }

  h4 {
    @extend .mdc-typography--headline4;
    opacity: 0.87;
    margin: 0;
  }
}
